var confirm_reset = function()
{
    var self = this;

    self.init = function()
    {
        $('body').on('click', '.js-confirm-reset', function(){
            var id = $(this).attr('data-id') || 'test';

            var $modal = $('.js-confirm-delete');

            // define html for modal
            //var html = '<p>Weet je zeker dat je \'' + name + '\' wilt verwijderen?</p>';
            var html = '<p>' + Lang.get('backend.js.reset_terms') + '</p>';
            html += '<div class="button-group small">';
            html += '<button class="button secondary" data-close aria-label="' + Lang.get('ClickAdmin::backend.js.delete_cancel') + '" type="button">' + Lang.get('ClickAdmin::backend.js.delete_cancel') + '</button>';
            html += '<button class="button alert js-confirm-reset-btn" aria-label="' + Lang.get('backend.js.reset_confirm') + '" type="button">' + Lang.get('backend.js.reset_confirm') + '</button>';
            html += '</div>';

            // add html to modal
            $modal.html( html );
            // open popup
            var popup = new Foundation.Reveal($modal);
            popup.open();
        });

        // listener for clicking on final delete button in confirm modal
        $('body').on('click', '.js-confirm-reset-btn', function(){
            // call form submit
            $('.js-confirm-reset-form').submit();
        })
    }
}


var select_data_type = function() {
    var self                = this;
    var $data_type          = $('.js-data-type-selection');
    var $transaction_type   = $('.js-transaction-type-selection');
    var $spending_options   = $('.js-spending-options-selection');
    var $spending_select    = $('.js-spending-option-select');
    var $saving_options     = $('.js-saving-options-selection');
    var $saving_select      = $('.js-saving-option-select');

    var $data_type_2        = $('.js-data-type-selection-2');
    var $spending_select_2  = $('.js-spending-option-select-2');
    var $saving_select_2    = $('.js-saving-option-select-2');
    var $saving_options_2   = $('.js-saving-options-selection-2');
    var $spending_options_2 = $('.js-spending-options-selection-2');

    /**
     * Init data type
     *
     * @author Rick van der Burg <rick@click.nl>
     * @return void
     */
    self.init = function() {
        $data_type.on('change', function(){
            self.changeDataType($(this).val(), $saving_options, $saving_select, $spending_options, $spending_select);
        });
        $data_type_2.on('change', function(){
            self.changeDataType($(this).val(), $saving_options_2, $saving_select_2, $spending_options_2, $spending_select_2);
        });
        $transaction_type.on('change', self.changeTransactionOption);
    }

    /**
     * After changing the data type value, show the correct ID
     *
     * @author Rick van der Burg <rick@click.nl>
     * @return void
     */
    self.changeDataType = function($current_type, $saving_options, $saving_select, $spending_options, $spending_select) {
        $saving_select.val('');
        $spending_select.val('');
        $spending_options.addClass('hide');
        $saving_options.addClass('hide');

        if($current_type == 'spaaractie') {
            $saving_options.removeClass('hide');
        } else if($current_type == 'wasactie') {
            $spending_options.removeClass('hide');
        }else {
            $saving_options.addClass('hide');
            $spending_options.addClass('hide');
        }
    }

}

/**
 * @author Yonita van Oudheusden <yonita@click.nl>
 * Functionality for the select2 select
 * @return object
 */
 var select2 = function() {
    var $data_type          = $('.js-data-type-selection');
    var $transaction_type   = $('.js-transaction-type-selection');
    var $spending_options   = $('.js-spending-options-selection');
    var $saving_options     = $('.js-saving-options-selection');
    var $website_url_input  = $('.js-website-url-input');
    var $select2_el         = $('.js-select2');
    var self = this;

    /**
     * @author Yonita van Oudheusden <yonita@click.nl>
     * Initialize the select2
     */
    self.init = function() {
        $select2_el.select2({
            placeholder: '-- Selecteer een optie --',
            templateResult: formatSavingOptions,
            // minimumResultsForSearch: Infinity
        });
         $transaction_type.on('change', self.changeTransactionOption);
    };

    /**
     * @author Yonita van Oudheusden <yonita@click.nl>
     * Populate the dropdown with image and text.
     * 
     * @param  object
     * @return  object
     */
    function formatSavingOptions (savingOption) {
        var id = $(savingOption.element).attr('value');
        var title = $(savingOption.element).attr('data-title');
        var subtitle = $(savingOption.element).attr('data-subtitle');
        var image = $(savingOption.element).attr('data-image');

        if (!savingOption.id) {
            return title;
        }
        // Saves the data in the select element
        var $savingOption = $(
            '<div class="select-2"><img src=" '+image+' " class="select2-image" /> <p class="select2-content"> <span>' + title + '</span>' + '<span>' + subtitle + '</span>' + '<span>' + 'ID = ' + id + '</span></p></div>'
        );

        // Rerturn the data in de $savingOption variable
        return $savingOption;
    };

    /**
     * @author Bas Lokerman <bas@click.nl>
     * After changing the data type value, show the correct select box
     * 
     * @return  void
     */ 
    self.changeTransactionOption = function() {

        $select2_el.val([]);
        $select2_el.select2({
            placeholder: '-- Selecteer een optie --',
            templateResult: formatSavingOptions,
        });

        $spending_options.addClass('hide');
        $saving_options.addClass('hide');
        $website_url_input.addClass('hide');
        if($(this).val() == '3') {
            $saving_options.removeClass('hide');
        }else if($(this).val() == '2'){
            $spending_options.removeClass('hide');    
        }else if($(this).val() == '12'){
            $website_url_input.removeClass('hide');    
        }else{
            $saving_options.addClass('hide');
            $spending_options.addClass('hide');
            $website_url_input.addClass('hide');
        }
    };
};